import "./../less/styles.scss";
import "./../less/details-header.scss";

import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import  FaFacebookF  from "../images/faFacebookF";
import  FaInstagram  from "../images/FaInstagram";
import  FaTwitter  from "../images/FaTwitter";
const MainLogo = "../images/d-golden-main-logo.png";



const Header = ({ siteTitle }) => (
 <div className="header-details">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" href="#">  <StaticImage src={MainLogo} quality={60} formats={["auto", "webp", "avif"]} /></a>
  
<div className="contact-block">

  <a href="tel:5016624618">
  <i class="fa fa-phone" aria-hidden="true"></i>
   <span> TEL: 501-662-4618</span>
  </a>
</div>
<ul className="social">
    <li>
      <a href="https://www.facebook.com/dgoldentours/" target="_blank">
          <FaFacebookF/>
      </a>
    </li>
    <li>
      <a href="https://www.instagram.com/dgolden_tours/" target="_blank">
      <FaInstagram/>
      </a>
    </li>
   
  </ul>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
      </li>
      
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="/all-tours" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Our Tours
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
        <a class="dropdown-item" href="/all-tours">View All Tours</a>
        <a class="dropdown-item" href="/fishing-tours-in-belize-with-dgoldentours">Fishing</a>
        <a class="dropdown-item" href="/snorkeling-in-belize-with-dgolden-tours">Snorkeling</a>
        <a class="dropdown-item" href="/bioluminescence-tours-in-belize-with-dgolden-tours">Bioluminescence</a>
          <a class="dropdown-item" href="/birding-in-belize-with-dgolden-tous">Birding</a>
        
          <a class="dropdown-item" href="/jungle-atv-tours-in-belize-with-dgolden-tours">ATV Jungle Ride</a>
          <a class="dropdown-item" href="/horseback-riding-in-belize-with-dgolden-tours">Horseback Riding</a>
          <a class="dropdown-item" href="/mayflower-hike-and-bocawina-zipline-with-dgolden-tours">Zipline Tour</a>
          <a class="dropdown-item" href="/botanical-garden-and-spice-farm-in-belize-with-dgolden-tours">Spice Farm Tour</a>
          <a class="dropdown-item" href="/night-hike-in-belize-with-dgolden-tours">Night Hike</a>
    
          
          
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/charter-tours">Charters</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/about-us">About Us</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="/contact-us">Contact Us</a>
      </li>
    </ul>
   
  </div>

</nav>
</div>
)

export default Header

import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/details-layout"
import Seo from "../../components/seo"
import * as styles from "../../less/styles.scss"
import "./contact.scss"

import Contact from "./components/contact"

const ContactUs = () => (
  <Layout>
    <Seo title="D-Golden Tours| D-Golden Tours Contact Us " />
    <Contact/>
  </Layout>
)

export default ContactUs;
